import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import numeral from 'numeral'
import { useState } from 'react'

import {
  GET_BRIDGE_TRANSACTIONS_FOR_ADDRESS_QUERY,
  BRIDGE_TRANSACTIONS_COUNT,
  COUNT_BY_TOKEN_ADDRESS,
  TOTAL_BRIDGE_AMOUNT,
  MEAN_BRIDGE_AMOUNT,
  MEDIAN_BRIDGE_AMOUNT,
} from '@graphql/queries'

import Card from '@tw/Card'
import { AssetImage } from '@components/misc/AssetImage'

import { CopyButtonIcon } from '@components/CopyButtonIcon'
import { Error, LongError } from '@components/Error'
import {
  AllTransactions,
  TransactionsLoader,
} from '@components/TransactionCard'
import { StandardPageContainer } from '@layouts/StandardPageContainer'
import {
  TotalTransactionsTitle,
  MeanValue,
  MedianValue,
  TotalValue,
} from '@pages/Home/TotalTransactions'
import { mode } from '@utils/math/statistics'
import { ChainInfo } from '@components/misc/ChainInfo'
import { Pagination } from '@components/Pagination'
import { useEffect } from 'react'

export function Address() {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  let { addressId } = useParams()

  const {
    error: initialError,
    loading: initialLoading,
    data: initialData,
  } = useQuery(GET_BRIDGE_TRANSACTIONS_FOR_ADDRESS_QUERY, {
    variables: {
      addressId,
    },
  })

  const {
    error: totalError,
    loading: totalLoading,
    data: totalData,
  } = useQuery(BRIDGE_TRANSACTIONS_COUNT, {
    variables: {
      duration: 'ALL_TIME',
      address: addressId,
    },
  })

  const [
    bridgeTransactions,
    { loading: pageLoading, error: pageError, data: pageData },
  ] = useLazyQuery(GET_BRIDGE_TRANSACTIONS_FOR_ADDRESS_QUERY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (initialData) {
      setData(initialData.bridgeTransactions)
    }
  }, [initialData])

  useEffect(() => {
    if (pageData) {
      setData(pageData.bridgeTransactions)
    }
  }, [pageData])

  useEffect(() => {
    if (totalData) {
      setTotalCount(totalData.countBridgeTransactions.value)
    }
  }, [totalData])

  const nextPage = () => {
    let newPage = page + 1
    setPage(newPage)

    bridgeTransactions({
      variables: { addressId, page: newPage },
    })
  }

  const prevPage = () => {
    if (page > 1) {
      let newPage = page - 1
      setPage(newPage)
      bridgeTransactions({
        variables: { addressId, page: newPage },
      })
    }
  }

  const resetPage = () => {
    setPage(1)
    bridgeTransactions({
      variables: { addressId, page: 1 },
    })
  }

  let content

  if (initialLoading || totalLoading || pageLoading) {
    content = <TransactionsLoader number={5} />
  } else if (initialError || totalError || pageError) {
    content = <Error text={'address'} param={addressId} />
  } else if (initialData.length === 0) {
    const longText = `Sorry, there are no transactions associated with that address: `
    content = <LongError longText={longText} param={addressId} />
  } else {
    let bridgeTransactions = data
    bridgeTransactions = _.orderBy(bridgeTransactions, 'fromInfo.time', [
      'desc',
    ])

    let originChainIds = _.map(bridgeTransactions, 'fromInfo.chainId')
    let destinationChainIds = _.map(bridgeTransactions, 'toInfo.chainId')

    let originChainIdMode = mode(originChainIds)
    let destinationChainIdMode = mode(destinationChainIds)

    content = (
      <>
        <SummaryStats
          address={addressId}
          topOriginChainId={originChainIdMode}
          topDestinationChainId={destinationChainIdMode}
        />
        <AllTransactions className="mb-5" txns={bridgeTransactions} />
        <Pagination
          totalCount={totalCount}
          page={page}
          resetPage={resetPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </>
    )
  }

  return (
    <StandardPageContainer title={'Address'}>{content}</StandardPageContainer>
  )
}

function CardTitle({ text }) {
  return (
    <div className="text-transparent bg-clip-text bg-gradient-to-l from-purple-300 to-purple-500">
      {text}
    </div>
  )
}

function SummaryStats({ address, topOriginChainId, topDestinationChainId }) {
  return (
    <div className="grid gap-4 mt-5 mb-5 sm:grid-cols-1 lg:md:grid-cols-3">
      <Card
        className="text-gray-500 border border-indigo-500 sm:col-span-1 lg:md:col-span-2 dark:bg-gray-900 dark:text-gray-200 hover:border-purple-500"
        title={<CardTitle text={'Summary Statistics'} />}
      >
        <div className="flex mb-5">
          <span className="mr-2">
            <CopyButtonIcon text={address} />
          </span>
          <span>{address}</span>
        </div>
        <div className="flex">
          <span className="mr-2">
            <TotalTransactionsTitle />
          </span>
          : <GetTransactionsCount address={address} />
        </div>
        <div className="flex">
          <span className="mr-2">
            <TotalValue />
          </span>
          : <GetTotalBridgeAmount address={address} />
        </div>
        <div className="flex">
          <span className="mr-2">
            <MeanValue />
          </span>
          : <GetMeanBridgeAmount address={address} />
        </div>
        <div className="flex">
          <span className="mr-2">
            <MedianValue />
          </span>
          : <GetMedianBridgeAmount address={address} />
        </div>
      </Card>
      <BridgeBehavior
        topDestinationChainId={topDestinationChainId}
        topOriginChainId={topOriginChainId}
        address={address}
      />
    </div>
  )
}

function BridgeBehavior({ topDestinationChainId, topOriginChainId, address }) {
  return (
    <Card
      className="text-gray-500 border border-indigo-500 dark:bg-gray-900 dark:text-gray-200 hover:border-purple-500"
      title={<CardTitle text={'Bridge Behavior'} />}
    >
      <div className="flex">
        <span className="mr-2">Top Origin Chain</span>
        <span>
          <ChainInfo chainId={topOriginChainId} />
        </span>
      </div>
      <div className="flex">
        <span className="mr-2">Top Destination Chain</span>
        <span>
          <ChainInfo chainId={topDestinationChainId} />
        </span>
      </div>
      <div>
        <GetMostCommonTokens address={address} />
      </div>
    </Card>
  )
}

function GetTransactionsCount({ address }) {
  const { data } = useQuery(BRIDGE_TRANSACTIONS_COUNT, {
    variables: {
      duration: 'ALL_TIME',
      address,
    },
  })

  if (data) {
    let { countBridgeTransactions } = data
    let formattedCount = numeral(countBridgeTransactions.value).format('0,0')
    return <>{formattedCount}</>
  } else {
    return (
      <div className="w-10 h-5 ml-2 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    )
  }
}

function GetTotalBridgeAmount({ address }) {
  const { data } = useQuery(TOTAL_BRIDGE_AMOUNT, {
    variables: {
      duration: 'ALL_TIME',
      address,
    },
  })

  if (data) {
    let { totalBridgeAmount } = data
    let formattedValue = numeral(totalBridgeAmount.value).format('$0,0')
    return <>{formattedValue}</>
  } else {
    return (
      <div className="w-10 h-5 ml-2 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    )
  }
}

function GetMeanBridgeAmount({ address }) {
  const { data } = useQuery(MEAN_BRIDGE_AMOUNT, {
    variables: {
      duration: 'ALL_TIME',
      address,
    },
  })

  if (data) {
    let { meanBridgeAmount } = data
    let formattedValue = numeral(meanBridgeAmount.value).format('$0,0')
    return <>{formattedValue}</>
  } else {
    return (
      <div className="w-10 h-5 ml-2 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    )
  }
}

function GetMedianBridgeAmount({ address }) {
  const { data } = useQuery(MEDIAN_BRIDGE_AMOUNT, {
    variables: {
      duration: 'ALL_TIME',
      address,
    },
  })

  if (data) {
    let { medianBridgeAmount } = data
    let formattedValue = numeral(medianBridgeAmount.value).format('$0,0')
    return <>{formattedValue}</>
  } else {
    return (
      <div className="w-10 h-5 ml-2 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    )
  }
}

function GetMostCommonTokens({ address }) {
  const { data } = useQuery(COUNT_BY_TOKEN_ADDRESS, {
    variables: {
      hours: 50000,
      address: address,
    },
  })

  if (data) {
    let { countByTokenAddress } = data
    return (
      <div className="mt-2">
        <div className="mt-2">Most Commonly Bridged Tokens</div>
        <div className="mt-2">
          <MostActive data={countByTokenAddress} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="w-10 h-4 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    )
  }
}

function MostActive({ data }) {
  return (
    <div className="text-sm">
      {data.slice(0, 3).map((entry, i) => (
        <AssetImage
          tokenAddress={entry.tokenAddress}
          chainId={entry.chainId}
          key={i}
          className="w-8 h-8 mr-4"
        />
      ))}
    </div>
  )
}
