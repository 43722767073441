import _ from 'lodash'

export function formatAmount(value) {
  if (value) {
    let calc = Number(value)
    calc = _.round(calc, 3).toLocaleString('en-US')
    if (calc.length > 12) {
      return '...'
    } else {
      return calc
    }
  } else {
    return '--'
  }
}
