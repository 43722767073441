export default function SynapseLogoSvg({ className="" }) {
  return (
    <svg
      className={`w-8 ${className}`}
      fillRule="nonzero"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 378 436"
    >
      <defs>
        <radialGradient
          cx="189"
          r="194.5"
          id="RadialGradient"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0.971722 0 0 0.975391 5.34447 5.36466)"
          cy="218"
        >
          <stop stopColor="#00a2e9" offset="0" />
          <stop stopColor="#ff00ff" offset="0" />
          <stop stopColor="#734ce2" offset="1" />
        </radialGradient>
      </defs>
      <g id="synapseNodes">
        <path d="M188.514+8.15905e-06C177.781+8.15905e-06+169.08+8.734+169.08+19.5078C169.08+30.2817+177.781+39.0157+188.514+39.0157C199.247+39.0157+207.949+30.2817+207.949+19.5078C207.949+8.734+199.247+8.15905e-06+188.514+8.15905e-06Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+10.7293C193.344+10.7293+197.26+14.6596+197.26+19.5078C197.26+24.356+193.344+28.2864+188.514+28.2864C183.684+28.2864+179.769+24.356+179.769+19.5078C179.769+14.6596+183.684+10.7293+188.514+10.7293Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M104.946+51.6958C94.2127+51.6958+85.5116+60.4297+85.5116+71.2036C85.5116+81.9774+94.2127+90.7114+104.946+90.7114C115.679+90.7114+124.38+81.9774+124.38+71.2036C124.38+60.4298+115.679+51.6958+104.946+51.6958Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+52.6711C263.292+52.6711+254.591+61.4052+254.591+72.179C254.591+82.9528+263.292+91.6868+274.026+91.6868C284.759+91.6868+293.46+82.9528+293.46+72.179C293.46+61.4052+284.759+52.6711+274.026+52.6711Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M104.946+62.4251C109.776+62.4251+113.692+66.3554+113.692+71.2036C113.692+76.0518+109.776+79.9821+104.946+79.9821C100.116+79.9821+96.2005+76.0518+96.2005+71.2036C96.2005+66.3554+100.116+62.4251+104.946+62.4251Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+63.4005C278.856+63.4005+282.771+67.3308+282.771+72.179C282.771+77.0272+278.856+80.9575+274.026+80.9575C269.196+80.9575+265.28+77.0272+265.28+72.179C265.28+67.3308+269.196+63.4004+274.026+63.4005Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+96.5638C176.171+96.5638+166.165+106.608+166.165+118.998C166.165+131.388+176.171+141.432+188.514+141.432C200.858+141.432+210.864+131.388+210.864+118.998C210.864+106.608+200.858+96.5638+188.514+96.5638Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M19.4344+99.4899C8.70113+99.4899+1.19209e-07+108.224+1.19209e-07+118.998C1.19209e-07+129.772+8.70113+138.506+19.4344+138.506C30.1677+138.506+38.8689+129.772+38.8689+118.998C38.8689+108.224+30.1677+99.4899+19.4344+99.4899Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M358.566+99.4899C347.832+99.4899+339.131+108.224+339.131+118.998C339.131+129.772+347.832+138.506+358.566+138.506C369.299+138.506+378+129.772+378+118.998C378+108.224+369.299+99.4899+358.566+99.4899Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+107.293C194.954+107.293+200.175+112.533+200.175+118.998C200.175+125.462+194.954+130.702+188.514+130.702C182.074+130.702+176.853+125.462+176.853+118.998C176.853+112.533+182.074+107.293+188.514+107.293Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M19.4344+110.219C24.2644+110.219+28.1799+114.15+28.1799+118.998C28.1799+123.846+24.2644+127.776+19.4344+127.776C14.6045+127.776+10.6889+123.846+10.6889+118.998C10.6889+114.15+14.6045+110.219+19.4344+110.219Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M358.566+110.219C363.395+110.219+367.311+114.15+367.311+118.998C367.311+123.846+363.395+127.776+358.566+127.776C353.736+127.776+349.82+123.846+349.82+118.998C349.82+114.15+353.736+110.219+358.566+110.219Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M102.031+145.333C89.6875+145.333+79.6812+155.377+79.6812+167.767C79.6812+180.157+89.6875+190.201+102.031+190.201C114.374+190.201+124.38+180.157+124.38+167.767C124.38+155.377+114.374+145.333+102.031+145.333Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+145.333C261.682+145.333+251.676+155.377+251.676+167.767C251.676+180.157+261.682+190.201+274.026+190.201C286.369+190.201+296.375+180.157+296.375+167.767C296.375+155.377+286.369+145.333+274.026+145.333Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M102.031+156.063C108.471+156.063+113.692+161.303+113.692+167.767C113.692+174.232+108.471+179.472+102.031+179.472C95.5908+179.472+90.3702+174.232+90.3702+167.767C90.3702+161.303+95.5908+156.063+102.031+156.063Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+156.063C280.466+156.063+285.686+161.303+285.686+167.767C285.686+174.232+280.466+179.472+274.026+179.472C267.586+179.472+262.365+174.232+262.365+167.767C262.365+161.303+267.586+156.063+274.026+156.063Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M189.486+190.201C174.46+190.201+162.278+202.429+162.278+217.512C162.278+232.595+174.46+244.823+189.486+244.823C204.512+244.823+216.694+232.595+216.694+217.512C216.694+202.429+204.512+190.201+189.486+190.201Z" fill="none" opacity="1" />
        <path d="M19.4344+198.004C8.70113+198.004+1.19209e-07+206.739+1.19209e-07+217.512C1.19209e-07+228.286+8.70113+237.02+19.4344+237.02C30.1677+237.02+38.8689+228.286+38.8689+217.512C38.8689+206.739+30.1677+198.004+19.4344+198.004Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M358.566+198.004C347.832+198.004+339.131+206.739+339.131+217.512C339.131+228.286+347.832+237.02+358.566+237.02C369.299+237.02+378+228.286+378+217.512C378+206.739+369.299+198.004+358.566+198.004Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M189.486+200.931C198.609+200.931+206.005+208.355+206.005+217.512C206.005+226.67+198.609+234.094+189.486+234.094C180.363+234.094+172.967+226.67+172.967+217.512C172.967+208.355+180.363+200.931+189.486+200.931Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M19.4344+208.734C24.2644+208.734+28.1799+212.664+28.1799+217.512C28.1799+222.36+24.2644+226.291+19.4344+226.291C14.6045+226.291+10.6889+222.36+10.6889+217.512C10.6889+212.664+14.6045+208.734+19.4344+208.734Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M358.566+208.734C363.395+208.734+367.311+212.664+367.311+217.512C367.311+222.36+363.395+226.291+358.566+226.291C353.736+226.291+349.82+222.36+349.82+217.512C349.82+212.664+353.736+208.734+358.566+208.734Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M104.946+243.848C92.6026+243.848+82.5964+253.892+82.5964+266.282C82.5964+278.672+92.6026+288.716+104.946+288.716C117.289+288.716+127.296+278.672+127.296+266.282C127.296+253.892+117.289+243.848+104.946+243.848Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+243.848C261.682+243.848+251.676+253.892+251.676+266.282C251.676+278.672+261.682+288.716+274.026+288.716C286.369+288.716+296.375+278.672+296.375+266.282C296.375+253.892+286.369+243.848+274.026+243.848Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M104.946+254.577C111.386+254.577+116.607+259.818+116.607+266.282C116.607+272.746+111.386+277.987+104.946+277.987C98.506+277.987+93.2853+272.746+93.2853+266.282C93.2853+259.818+98.506+254.577+104.946+254.577Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+254.577C280.466+254.577+285.686+259.818+285.686+266.282C285.686+272.746+280.466+277.987+274.026+277.987C267.586+277.987+262.365+272.746+262.365+266.282C262.365+259.818+267.586+254.577+274.026+254.577Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+292.617C176.171+292.617+166.165+302.661+166.165+315.051C166.165+327.441+176.171+337.485+188.514+337.485C200.858+337.485+210.864+327.441+210.864+315.051C210.864+302.661+200.858+292.617+188.514+292.617Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M19.4344+296.519C8.70113+296.519+1.19209e-07+305.253+1.19209e-07+316.027C1.19209e-07+326.801+8.70113+335.535+19.4344+335.535C30.1677+335.535+38.8689+326.801+38.8689+316.027C38.8689+305.253+30.1677+296.519+19.4344+296.519Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M358.566+296.519C347.832+296.519+339.131+305.253+339.131+316.027C339.131+326.801+347.832+335.535+358.566+335.535C369.299+335.535+378+326.801+378+316.027C378+305.253+369.299+296.519+358.566+296.519Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+303.347C194.954+303.347+200.175+308.587+200.175+315.051C200.175+321.516+194.954+326.756+188.514+326.756C182.074+326.756+176.853+321.516+176.853+315.051C176.853+308.587+182.074+303.347+188.514+303.347Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M19.4344+307.248C24.2644+307.248+28.1799+311.179+28.1799+316.027C28.1799+320.875+24.2644+324.805+19.4344+324.805C14.6045+324.805+10.6889+320.875+10.6889+316.027C10.6889+311.179+14.6045+307.248+19.4344+307.248Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M358.566+307.248C363.395+307.248+367.311+311.179+367.311+316.027C367.311+320.875+363.395+324.805+358.566+324.805C353.736+324.805+349.82+320.875+349.82+316.027C349.82+311.179+353.736+307.248+358.566+307.248Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M104.946+345.289C94.2127+345.289+85.5116+354.023+85.5116+364.796C85.5116+375.57+94.2127+384.304+104.946+384.304C115.679+384.304+124.38+375.57+124.38+364.796C124.38+354.023+115.679+345.289+104.946+345.289Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+346.264C263.292+346.264+254.591+354.998+254.591+365.772C254.591+376.546+263.292+385.28+274.026+385.28C284.759+385.28+293.46+376.546+293.46+365.772C293.46+354.998+284.759+346.264+274.026+346.264Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M104.946+356.018C109.776+356.018+113.692+359.948+113.692+364.796C113.692+369.645+109.776+373.575+104.946+373.575C100.116+373.575+96.2005+369.645+96.2005+364.796C96.2005+359.948+100.116+356.018+104.946+356.018Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M274.026+356.993C278.856+356.993+282.771+360.924+282.771+365.772C282.771+370.62+278.856+374.55+274.026+374.55C269.196+374.55+265.28+370.62+265.28+365.772C265.28+360.924+269.196+356.993+274.026+356.993Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+396.984C177.781+396.984+169.08+405.718+169.08+416.492C169.08+427.266+177.781+436+188.514+436C199.247+436+207.949+427.266+207.949+416.492C207.949+405.718+199.247+396.984+188.514+396.984Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M188.514+407.714C193.344+407.714+197.26+411.644+197.26+416.492C197.26+421.34+193.344+425.271+188.514+425.271C183.684+425.271+179.769+421.34+179.769+416.492C179.769+411.644+183.684+407.714+188.514+407.714Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M266.252+164.643L197.26+206.585L202.817+215.76L271.809+173.818L266.252+164.643Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M110.473+163.683L104.733+172.736L174.697+217.604L180.437+208.551L110.473+163.683Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M91.767+168.468L27.6334+208.459L33.2815+217.573L97.4152+177.582L91.767+168.468Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M352.28+215.226L283.287+257.168L288.844+266.343L357.837+224.401L352.28+215.226Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M177.37+219.128L108.377+261.07L113.934+270.244L182.927+228.303L177.37+219.128Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M201.845+217.863L196.106+226.916L266.07+271.784L271.809+262.731L201.845+217.863Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M285.292+165.207L279.552+174.26L349.516+219.128L355.256+210.075L285.292+165.207Z" fill="url(#RadialGradient)" opacity="1" />
        <path d="M27.4512+216.324L21.7119+225.376L91.6759+270.244L97.4152+261.192L27.4512+216.324Z" fill="url(#RadialGradient)" opacity="1" />
      </g>
    </svg>
  )
}



