import Card from '@components/tailwind/Card'

export function Error({ text, param }) {
  return (
    <Card className="mt-10 text-white">
      <div className="mb-2">
        Sorry. We can't find the {text}
        <span className="text-gray-300"> {param}</span>.
      </div>
      <div>
        Please click{' '}
        <a href={'/'} className="text-gray-300 hover:underline">
          here
        </a>{' '}
        to go back to the main page.
      </div>
    </Card>
  )
}

export function LongError({ longText, param }) {
  return (
    <Card className="mt-10 text-white">
      <div className="mb-2">
        {longText}
        <span className="text-gray-300"> {param}</span>.
      </div>
      <div>
        Please click{' '}
        <a href={'/'} className="text-gray-300 hover:underline">
          here
        </a>{' '}
        to go back to the main page.
      </div>
    </Card>
  )
}

export function AllTransactionsError() {
  return (
    <Card className="mt-10 text-white">
      <div className="mb-2">Sorry, there was a problem.</div>
      <div>
        Please click{' '}
        <a
          href={'/bridge-transactions'}
          className="text-gray-300 hover:underline"
        >
          here
        </a>{' '}
        to go back to the transactions page.
      </div>
    </Card>
  )
}
