import { ChevronDoubleRightIcon } from '@heroicons/react/outline'

import Card from '@components/tailwind/Card'

export function BridgeTransactionLoader() {
  return (
    <div className="mt-10">
      <div className="flex justify-center pb-10 space-x-2">
        <div className="flex-0">
          <OriDestInfoLoader label="From " />
        </div>
        <div className="flex-shrink px-4 align-middle">
          <ChevronDoubleRightIcon className="w-6 h-6 align-middle mt-7 text-slate-500" />
        </div>
        <div className="flex-0">
          <OriDestInfoLoader label="To " />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <SourceCardLoader />
      </div>
    </div>
  )
}

function OriDestInfoLoader() {
  return (
    <div>
      <Card className={`shadow-lg pb-4`}>
        <div className="flex flex-col text-center">
          <div className="mb-5">
            <Loading />
          </div>
          <div>
            <Loading />
          </div>
        </div>
      </Card>
    </div>
  )
}

function SourceCardLoader () {
  return (
    <div className="xs:w-full sm:w-3/4 md:w-3/4 lg:w-1/2">
      <Card className="mb-2">
        <BigLoader />
      </Card>
      <Card>
        <BigLoader />
      </Card>
    </div>
  )
}

function Loading () {
  return <div className="w-40 h-4 rounded dark:bg-slate-500 animate-pulse" />
}

function BigLoader () {
  return <div className="w-40 h-10 rounded dark:bg-slate-500 animate-pulse" />
}
