import { CHAIN_EXPLORER_URLS } from '@constants/networks'
export const BASE_PATH = '/'
export const ANALYTICS_PATH = 'https://analytics.synapseprotocol.com/'
export const BRIDGE_TRANSACTIONS_URL = '/bridge-transactions'
export const ADDRESSES_URL = '/addresses'
export const AIRDROP_PATH = '/claim'
export const SWAP_PATH = '/swap'
export const STAKE_PATH = '/stake'
export const POOLS_PATH = '/pools'
export const BRIDGE_PATH = '/'
export const CONTRACTS_PATH = '/contracts'
export const PORTFOLIO_PATH = '/portfolio'
export const STATISTICS_PATH = '/statistics'
export const LANDING_PATH = '/landing'
export const SYNAPSE_PFP_PATH = '/returntomonke'
export const DOCS_URL = 'https://docs.synapseprotocol.com'
export const DISCORD_URL = 'https://discord.gg/synapseprotocol'
export const TELEGRAM_URL = 'https://t.me/synapseprotocol'
export const FORUM_URL = 'https://forum.synapseprotocol.com/'
export const TWITTER_URL = 'https://twitter.com/SynapseProtocol'
export const GITHUB_URL = 'https://github.com/synapsecns'
export const SYNAPSE_DOCS_URL = 'https://docs.synapseprotocol.com'

export function getBridgeTransactionUrl({ hash, chainIdFrom, chainIdTo }) {
  let url = BRIDGE_TRANSACTIONS_URL

  if (hash) {
    url += `/${hash}`
  }
  url += '?'

  if (chainIdFrom) {
    url += `chainIdFrom=${chainIdFrom}`
  }

  if (chainIdTo) {
    if (url[url.length - 1] !== '?') {
      url += '&'
    }
    url += `chainIdTo=${chainIdTo}`
  }

  return url
}

export function getAddressesUrl({ address, chainIdFrom, chainIdTo }) {
  let url = ADDRESSES_URL

  if (address) {
    url += `/${address}`
  }
  url += '?'

  if (chainIdFrom) {
    url += `chainIdFrom=${chainIdFrom}`
  }

  if (chainIdTo) {
    if (url[url.length - 1] !== '?') {
      url += '&'
    }
    url += `chainIdTo=${chainIdTo}`
  }

  return url
}

export function getExplorerTxUrl({ hash, data, chainId, type = 'tx' }) {
  let baseUrl = CHAIN_EXPLORER_URLS[chainId]

  return `${baseUrl}/${type}/${hash ?? data}`
}

export function getExplorerAddressUrl({
  address,
  data,
  chainId,
  type = 'address',
}) {
  let baseUrl = CHAIN_EXPLORER_URLS[chainId]

  return `${baseUrl}/${type}/${address ?? data}`
}
