import { ApolloClient, InMemoryCache } from '@apollo/client'
import { BRIDGE_TXN_API_GRAPHQL_URL } from '@graphql'

const client = new ApolloClient({
  uri: BRIDGE_TXN_API_GRAPHQL_URL,
  cache: new InMemoryCache(),
  fetchPolicy: 'network-only',
  fetchOptions: {
    mode: 'no-cors',
  },
})

export default client
