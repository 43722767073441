import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SearchIcon } from '@heroicons/react/outline'

import { validateAndParseAddress } from '@utils/validateAndParseAddress'

export function Search() {
  const [searchField, setSearchField] = useState('')
  const [errors, setErrors] = useState('')
  const [isValid, setValid] = useState(false)

  useEffect(() => {}, [searchField])

  const handleChange = (e) => {
    setSearchField(e.target.value)
    setErrors('')

    if (e.target.value === '') {
      setValid(false)
      setErrors('')
    } else if (!e.target.value) {
      setValid(false)
      setErrors('Field cannot be empty.')
    } else if (!validateAndParseAddress(e.target.value)) {
      setValid(false)
      setErrors('Invalid address.')
    } else {
      setValid(true)
      setErrors('')
    }
  }

  return (
    <form className="flex items-center mt-5">
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
        </div>
        <input
          type="text"
          id="simple-search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-purple-700 focus:border-purple-500 block w-full pl-10 p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
          placeholder="Search by address"
          onChange={handleChange}
          value={searchField}
        />
        {!isValid && errors ? (
          <div
            className="absolute p-4 mt-1 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <span className="font-medium">{!isValid && errors}</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <Link
        to={`/addresses/${searchField}`}
        className={!isValid ? 'pointer-events-none opacity-50' : ''}
      >
        <button
          type="submit"
          className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <SearchIcon className="w-5 h-5" />
        </button>
      </Link>
    </form>
  )
}
