import { MoreInfoItem } from './MoreInfoItem'
import { ANALYTICS_PATH } from '@urls'

export function MoreInfoButtons() {
  return (
    <MoreInfoItem
      className="mdl:hidden"
      to={ANALYTICS_PATH}
      labelText="Analytics"
      description="See preliminary analytics of the bridge"
    />
  )
}
