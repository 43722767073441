import { gql } from '@apollo/client'

const SINGLE_SIDE_INFO_FRAGMENT = gql`
  fragment SingleSideInfo on PartialInfo {
    chainId
    address
    hash: txnHash
    value
    formattedValue
    tokenAddress
    tokenSymbol
    time
  }
`

const BRIDGE_TRANSACTION_INFO_FRAGMENT = gql`
  fragment TransactionInfo on BridgeTransaction {
    fromInfo {
      ...SingleSideInfo
    }
    toInfo {
      ...SingleSideInfo
    }

    kappa
    pending
    swapSuccess
  }

  ${SINGLE_SIDE_INFO_FRAGMENT}
`

export const GET_BRIDGE_TRANSACTION_BY_HASH_QUERY = gql`
  query GetBridgeTransactionsByHash($txnHash: String!) {
    bridgeTransactions(txnHash: $txnHash) {
      ...TransactionInfo
    }
  }
  ${BRIDGE_TRANSACTION_INFO_FRAGMENT}
`

export const GET_BRIDGE_TRANSACTIONS_FOR_ADDRESS_QUERY = gql`
  query GetBridgeTransactionsForAddress($addressId: String!, $page: Int) {
    bridgeTransactions(address: $addressId, page: $page) {
      ...TransactionInfo
    }
  }
  ${BRIDGE_TRANSACTION_INFO_FRAGMENT}
`

export const GET_LATEST_BRIDGE_TRANSACTIONS_QUERY = gql`
  query LatestBridgeTransactions {
    latestBridgeTransactions {
      ...TransactionInfo
    }
  }
  ${BRIDGE_TRANSACTION_INFO_FRAGMENT}
`

export const GET_BRIDGE_TRANSACTIONS_QUERY = gql`
  query GetBridgeTransactionsQuery($chainId: Int, $page: Int) {
    bridgeTransactions(chainId: $chainId, page: $page) {
      ...TransactionInfo
    }
  }
  ${BRIDGE_TRANSACTION_INFO_FRAGMENT}
`

export const COUNT_BY_CHAIN_ID = gql`
  query CountByChainId($direction: Direction, $hours: Int) {
    countByChainId(direction: $direction, hours: $hours) {
      chainId
      count
    }
  }
`

export const BRIDGE_TRANSACTIONS_COUNT = gql`
  query CountBridgeTransactions(
    $duration: Duration!
    $chainId: Int
    $address: String
  ) {
    countBridgeTransactions(
      duration: $duration
      chainId: $chainId
      address: $address
    ) {
      value
    }
  }
`

export const COUNT_BY_TOKEN_ADDRESS = gql`
  query CountByTokenAddress(
    $direction: Direction
    $hours: Int
    $address: String
  ) {
    countByTokenAddress(
      direction: $direction
      hours: $hours
      address: $address
    ) {
      tokenAddress
      chainId
      count
    }
  }
`

export const ADDRESS_RANKING = gql`
  query AddressRanking($hours: Int) {
    addressRanking(hours: $hours) {
      address
      count
    }
  }
`

export const TOTAL_BRIDGE_AMOUNT = gql`
  query TotalBridgeAmount(
    $address: String
    $duration: Duration!
    $chainId: Int
  ) {
    totalBridgeAmount(
      address: $address
      duration: $duration
      chainId: $chainId
    ) {
      value
    }
  }
`

export const MEAN_BRIDGE_AMOUNT = gql`
  query MeanBridgeAmount(
    $address: String
    $duration: Duration!
    $chainId: Int
  ) {
    meanBridgeAmount(
      address: $address
      duration: $duration
      chainId: $chainId
    ) {
      value
    }
  }
`

export const MEDIAN_BRIDGE_AMOUNT = gql`
  query MedianBridgeAmount(
    $address: String
    $duration: Duration!
    $chainId: Int
  ) {
    medianBridgeAmount(
      address: $address
      duration: $duration
      chainId: $chainId
    ) {
      value
    }
  }
`
