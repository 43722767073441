import { useQuery } from '@apollo/client'
import { PresentationChartLineIcon } from '@heroicons/react/outline'

import { COUNT_BY_TOKEN_ADDRESS } from '@graphql/queries'

import { AssetImage } from '@components/misc/AssetImage'
import { ContainerCard } from './ContainerCard'

export function TokenActivityCard() {
  return (
    <ContainerCard title={<TokenActivityTitle />}>
      <TokenActivity />
    </ContainerCard>
  )
}

function TokenActivityTitle() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <PresentationChartLineIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span className="text-transparent bg-clip-text bg-gradient-to-l from-purple-300 to-purple-500">
        Token Activity <span className="text-sm">(24 hours)</span>
      </span>
    </div>
  )
}

function TokenActivity() {
  const { data: fromData } = useQuery(COUNT_BY_TOKEN_ADDRESS, {
    variables: {
      source: 'origin',
      hours: 24,
    },
  })
  const { data: toData } = useQuery(COUNT_BY_TOKEN_ADDRESS, {
    variables: {
      source: 'destination',
      hours: 24,
    },
  })
  if (fromData && toData) {
    let { countByTokenAddress: fromCountByTokenAddress } = fromData
    let { countByTokenAddress: toCountByTokenAddress } = toData
    return (
      <div className="mt-5 mb-5">
        <div className="mb-5">
          <div className="mb-4 text-white">Most Active Sent</div>
          <MostActive data={fromCountByTokenAddress} />
        </div>
        <div>
          <div className="mb-4 text-white">Most Active Received</div>
          <MostActive data={toCountByTokenAddress} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="mt-5 mb-5">
        <div className="mb-5">
          <div className="mb-4 text-white">Most Active Sent</div>
          <MostActiveLoader />
        </div>
        <div>
          <div className="mb-4 text-white">Most Active Received</div>
          <MostActiveLoader />
        </div>
      </div>
    )
  }
}

function MostActive({ data }) {
  return (
    <div className="text-sm">
      {data.slice(0, 5).map((entry, i) => (
        <AssetImage
          tokenAddress={entry.tokenAddress}
          chainId={entry.chainId}
          key={i}
          className="w-8 h-8 mr-4"
        />
      ))}
    </div>
  )
}

function MostActiveLoader() {
  return (
    <div className="text-sm">
      <div className="h-3 bg-slate-400 dark:bg-slate-500 animate-pulse" />
      <div className="h-3 bg-slate-400 dark:bg-slate-500 animate-pulse" />
      <div className="h-3 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    </div>
  )
}
