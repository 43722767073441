import { MoreInfoItem } from './MoreInfoItem'
import { BRIDGE_PATH, SWAP_PATH, POOLS_PATH, STAKE_PATH } from '@urls'

export function MobileBarButtons() {
  return (
    <>
      <MoreInfoItem to={BRIDGE_PATH} labelText="Bridge" />
      <MoreInfoItem to={SWAP_PATH} labelText="Swap" />
      <MoreInfoItem to={POOLS_PATH} labelText="Pools" />
      <MoreInfoItem to={STAKE_PATH} labelText="Stake" />
    </>
  )
}
