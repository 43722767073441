import SynapseLogoSvg from '@icons/SynapseLogoSvg'

export function SynapseTitleLogo({ showText }) {
  return (
    <div>
      <a href={'/'} className="flex">
        <div className="flex items-center flex-shrink-0 py-1 ">
          <div className="mr-2">
            <SynapseLogoSvg />
          </div>
          <span
            className={`
              ${showText ? '' : 'hidden'}
              font-medium text-2xl tracking-wider pl-2
              bg-clip-text text-transparent bg-gradient-to-r
              from-purple-600 to-blue-600
              active:from-purple-700 active:to-blue-700
              hover:animate-pulse
              transform transition-all
            `}
          >
            Synapse
          </span>
        </div>
      </a>
    </div>
  )
}
