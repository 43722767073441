export const leaderboardData = [
  {
    rank: 1,
    address: '0xE9b50da104cfe9FF07CaDa6Dab6DF4fF912791aB',
    transactionCount: 100000,
  },
  {
    rank: 2,
    address: '0x7d32AB48B3a1d5C340fefBe9c9Bb6C4Db27EaDAb',
    transactionCount: 80000,
  },
  {
    rank: 3,
    address: '0x4BA096c1e0e72AF40c172B93b77602eE4e30c739',
    transactionCount: 75000,
  },
  {
    rank: 4,
    address: '0xAbD61Bd9bB8381Db076C18E24317AB799Ea51e83',
    transactionCount: 70000,
  },
  {
    rank: 5,
    address: '0x53e2010763767a6ADe1ECd5eBCA3B07d4604Ae4b',
    transactionCount: 65000,
  },
  {
    rank: 6,
    address: '0x926fB09A693c00d29E86E6e4e241d778D9dE702D',
    transactionCount: 60000,
  },
  {
    rank: 7,
    address: '0xf114dF3b6fD98BC0D25Ed2eD3bF16710196c2F45',
    transactionCount: 52000,
  },
  {
    rank: 8,
    address: '0x97E28a3644c3920292390B10692a04510E37F6fb',
    transactionCount: 32000,
  },
  {
    rank: 9,
    address: '0x5951EDF25c76fA718e9CD6f46a88a7D97aD0D9A7',
    transactionCount: 30000,
  },
  {
    rank: 10,
    address: '0x30882E2Ae111624545db99641067277531fF12d9',
    transactionCount: 29000,
  },
]

export const mostBridgedTokens = [
  {
    tokenAddress: '0x985458E523dB3d53125813eD68c274899e9DfAb4',
    chainId: 1666600000,
    rank: 1,
  },
  {
    tokenAddress: '0xCCBf7c451F81752F7d2237F2c18C371E6e089E69',
    chainId: 43114,
    rank: 2,
  },
  {
    tokenAddress: '0xCCb93dABD71c8Dad03Fc4CE5559dC3D89F67a260',
    chainId: 53935,
    rank: 3,
  },
  {
    tokenAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    chainId: 250,
    rank: 4,
  },
  {
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    rank: 5,
  },
]

export const mostActiveChains = [
  {
    chainId: 1,
    rank: 1,
  },
  {
    chainId: 53935,
    rank: 2,
  },
  {
    chainId: 43114,
    rank: 3,
  },
  {
    chainId: 1313161554,
    rank: 4,
  },
  {
    chainId: 1666600000,
    rank: 5,
  },
]
