import { NavLink } from 'react-router-dom'
import { BASE_PATH } from '@urls'

export function TopBarNavLink({ labelText, to, className }) {
  // const location = useLocation()

  // const match = location.pathname.split('/')[1] === to.split('/')[1] && (to !== "#")
  // const isInternal = (to[0] === "/") || (to[0] === "#")
  const match = true
  const isInternal = true
  const linkContent = (
    <div className={`py-2 px-2 ${className}`}>
      <span
        className={`
            transform-gpu transition-all duration-75
            ${
              !match &&
              `
              group-hover:text-gray-900
              dark:group-hover:text-gray-300
              `
            }
          `}
      >
        {labelText}
      </span>
    </div>
  )

  const linkClassName = `
    group items-center px-2 my-2 font-light tracking-wide rounded-md
    bg-opacity-50
    transform-gpu transition-all duration-75
    text-gray-800
    dark:text-gray-400
    dark:hover:text-gray-300
    dark:hover:bg-gray-800
  `

  if (isInternal) {
    return (
      <NavLink
        // exact={(BASE_PATH === to) && (to !== "#")}
        to={to}
        className={linkClassName}
        // activeClassName={`
        //   !font-normal
        //   !bg-opacity-90
        //   !bg-clip-text !text-transparent !bg-gradient-to-r
        //   !from-purple-600 !to-blue-600
        //   active:!from-purple-700 active:!to-blue-700
        // `}
        // activeStyle={{ textShadow: "0px 0px #00000000" }}
      >
        {linkContent}
      </NavLink>
    )
  } else {
    return (
      <a href={to} target="_blank" className={linkClassName} rel="noreferrer">
        {linkContent}
      </a>
    )
  }
}
