import Card from '@tw/Card'

export function TransactionCardLoader() {
  const animation = (
    <div className="w-20 h-3 mb-2 rounded bg-slate-400 dark:bg-slate-500 animate-pulse"></div>
  )

  return (
    <Card className="mt-5 mb-5 hover:shadow-sm hover:shadow-indigo-500">
      <div className="grid gap-2 text-black lg:grid-cols-4 sm:grid-cols-1 dark:text-white whitespace-nowrap">
        <div>{animation}</div>
        <div>{animation}</div>
        <div>{animation}</div>
        <div className="mb-5 lg:mb-0">{animation}</div>
      </div>

      <div className="grid gap-2 text-black lg:grid-cols-4 sm:grid-cols-1 dark:text-white whitespace-nowrap">
        <div>{animation}</div>
        <div>{animation}</div>
        <div>{animation}</div>
        <div className="mb-5 lg:mb-0">{animation}</div>
      </div>
    </Card>
  )
}

export function TransactionsLoader({ number }) {
  return [...Array(number).keys()].map((i) => <TransactionCardLoader key={i} />)
}
