import { Routes, Route } from 'react-router-dom'
import { BridgeTransactions } from '@pages/BridgeTransactions'
import { BridgeTransaction } from '@pages/BridgeTransaction'
import { Address } from '@pages/Address'
import { Home } from '@pages/Home'
import { Leaderboard } from '@pages/Leaderboard'
import { BRIDGE_TRANSACTIONS_URL, ADDRESSES_URL } from '@urls'

export default function App() {
  return (
    <Routes>
      <Route exact path={'/'} element={<Home />} />
      <Route
        exact
        path={BRIDGE_TRANSACTIONS_URL}
        element={<BridgeTransactions />}
      />
      <Route
        path={`${BRIDGE_TRANSACTIONS_URL}/:bridgeTransactionId`}
        element={<BridgeTransaction />}
      />
      <Route path={`${ADDRESSES_URL}/:addressId`} element={<Address />} />
      <Route path={'/leaderboard'} element={<Leaderboard />} />
    </Routes>
  )
}
