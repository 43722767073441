import Card from '@tw/Card'

import { TOKEN_HASH_MAP } from '@constants/tokens/basic'
import { CHAIN_INFO_MAP } from '@constants/networks'
import { getNetworkShadow, getNetworkTextColor } from '@styles/networks'
import { getCoinTextColor } from '@styles/coins'
import { formatAmount } from '@utils/formatAmount'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

export function OriDestInfo({
  time,
  chainId,
  address,
  txnHash,
  tokenAddress,
  tokenSymbol,
  formattedValue,
  value,
  label,
}) {
  tokenAddress = tokenAddress && tokenAddress.toLowerCase()
  const t = chainId && tokenAddress && TOKEN_HASH_MAP[chainId][tokenAddress]
  const { chainName, chainImg } = CHAIN_INFO_MAP[chainId] ?? {}

  return (
    <Card className={`shadow-lg ${getNetworkShadow(chainId)} pb-4`}>
      <div className="flex flex-col text-center">
        <span className="text-slate-500">
          {label}{' '}
          <span className={getNetworkTextColor(chainId)}>{chainName}</span>
          <img
            className="inline w-4 h-4 ml-1 rounded-lg"
            src={chainImg}
            alt=""
          />
        </span>
        <IconAndAmount
          t={t}
          tokenSymbol={tokenSymbol}
          formattedValue={formattedValue}
        />
      </div>
    </Card>
  )
}

function IconAndAmount({ formattedValue, t, tokenSymbol }) {
  let showToken

  if (tokenSymbol) {
    showToken = (
      <span className={t && `${getCoinTextColor(t)}`}> {tokenSymbol} </span>
    )
  } else {
    showToken = <span className="text-2xl text-slate-400">--</span>
  }

  if (!!formattedValue) {
    return (
      <div>
        <img className="inline-block w-5 h-5 mr-2 -mt-2" src={t?.icon} alt="" />
        <span className="text-2xl text-slate-400">
          {formatAmount(formattedValue)}
        </span>
        {showToken}
      </div>
    )
  } else {
    return (
      <div>
        <QuestionMarkCircleIcon
          className="inline-block w-5 h-5 mr-2 -mt-2"
          strokeWidth={2}
        />
        {showToken}
      </div>
    )
  }
}
