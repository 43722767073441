import { TopBarNavLink } from './TopBarNavLink'
import {
  SWAP_PATH,
  POOLS_PATH,
  BRIDGE_PATH,
  STAKE_PATH,
  ANALYTICS_PATH,
} from '@urls'

export function TopBarButtons() {
  return (
    <>
      {/* <TopBarNavLink to={BRIDGE_PATH} labelText="Bridge" />
      <TopBarNavLink to={SWAP_PATH} labelText="Swap" />
      <TopBarNavLink to={POOLS_PATH} labelText="Pools" />
      <TopBarNavLink to={STAKE_PATH} labelText="Stake" /> */}
      <TopBarNavLink
        className="hidden mdl:block"
        to={ANALYTICS_PATH}
        labelText="Analytics"
      />
    </>
  )
}
