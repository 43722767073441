import { formatDistanceToNow } from 'date-fns'
import { ArrowNarrowRightIcon } from '@heroicons/react/outline'

import { ChainImage } from '@components/misc/ChainImage'
import Grid from '@tw/Grid'
import { AssetImage } from '@components/misc/AssetImage'
import { getCoinTextColor } from '@utils/styles/coins'
import { formatAmount } from '@utils/formatAmount'
import { StyleHash, StyleAddress } from '@utils/styleFields'

export function InfoRow({ txLabel, addrLabel, info, textClassName }) {
  const isDestination = txLabel === 'Destination'

  return (
    <Grid
      gap={2}
      cols={{ xs: 1, sm: 1, md: 1, lg: 4 }}
      className="pb-1 text-black dark:text-white whitespace-nowrap"
    >
      <div>
        <div className="flex items-center align-middle">
          <span>
            {isDestination ? (
              <ArrowNarrowRightIcon className="w-6 h-6 mr-2 text-gray-400" />
            ) : (
              ''
            )}
          </span>
          <ChainImage chainId={info.chainId} />
          <span className={textClassName}>
            {txLabel}: <StyleHash {...info} />
          </span>
        </div>
      </div>
      <div className="flex items-center">
        <span>
          {isDestination ? (
            <ArrowNarrowRightIcon className="w-6 h-6 mr-2 text-transparent" />
          ) : (
            ''
          )}
        </span>
        <AssetImage tokenAddress={info.tokenAddress} chainId={info.chainId} />
        <span className={`ml-1 mr-2 ${textClassName}`}>
          {formatAmount(info.formattedValue)}
        </span>
        <span
          className={`text-sm ${getCoinTextColor({
            symbol: info.tokenSymbol,
          })}`}
        >
          {' '}
          {info.tokenSymbol}
        </span>
      </div>
      <div className={`flex items-center ${textClassName}`}>
        {isDestination ? (
          <ArrowNarrowRightIcon className="w-6 h-6 mr-2 text-transparent" />
        ) : (
          ''
        )}
        <StyleAddress {...info} />
      </div>
      <div className={`flex items-center ${textClassName}`}>
        <span>
          {isDestination ? (
            <ArrowNarrowRightIcon className="w-6 h-6 mr-2 text-transparent" />
          ) : (
            ''
          )}
        </span>
        <span>
          {addrLabel}: <TimeAgo timestamp={info.time} />
        </span>
      </div>
    </Grid>
  )
}

function TimeAgo({ timestamp }) {
  let timeAgo = '--'
  if (timestamp) {
    const unixTimestamp = timestamp
    const milliseconds = unixTimestamp * 1000
    const date = new Date(milliseconds)
    const timePeriod = formatDistanceToNow(date)

    timeAgo = `${timePeriod} ago`
  }

  return <span>{timeAgo}</span>
}
