import _ from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useState, useEffect, useRef } from 'react'

import { CHAIN_INFO_MAP } from '@constants/networks'
import {
  GET_LATEST_BRIDGE_TRANSACTIONS_QUERY,
  GET_BRIDGE_TRANSACTIONS_QUERY,
} from '@graphql/queries'
import { AllTransactionsError } from '@components/Error'
import { Pagination } from '@components/Pagination'
import {
  AllTransactions,
  TransactionsLoader,
} from '@components/TransactionCard'
import { StandardPageContainer } from '@layouts/StandardPageContainer'
import {
  getNetworkButtonBgClassName,
  getNetworkButtonBgClassNameActive,
  getNetworkButtonBorderHover,
  getNetworkButtonBorderActive,
} from '@styles/networks'
import Button from '@tw/Button'
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline'

const nameToChainIds = {}
Object.keys(CHAIN_INFO_MAP).forEach((chainId) => {
  let name = CHAIN_INFO_MAP[chainId].chainName
  return (nameToChainIds[name] = Number(chainId))
})

const suggestions = Object.keys(CHAIN_INFO_MAP)
  .map((chainId) => CHAIN_INFO_MAP[chainId].chainName)
  .filter(Boolean)
  .filter((chainName) => chainName !== 'Terra')
  .sort()

export function BridgeTransactions() {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const {
    loading: latestLoading,
    error: latestError,
    data: latestData,
  } = useQuery(GET_LATEST_BRIDGE_TRANSACTIONS_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [
    bridgeTransactions,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_BRIDGE_TRANSACTIONS_QUERY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (latestData) {
      setData(latestData.latestBridgeTransactions)
    }
  }, [latestData])

  useEffect(() => {
    if (searchData) {
      setData(searchData.bridgeTransactions)
    }
  }, [searchData])

  let content
  const nextPage = () => {
    let newPage = page + 1
    setPage(newPage)

    bridgeTransactions({
      variables: { page: newPage },
    })
  }

  const prevPage = () => {
    if (page > 1) {
      let newPage = page - 1
      setPage(newPage)
      bridgeTransactions({
        variables: { page: newPage },
      })
    }
  }

  const resetPage = () => {
    setPage(1)
    bridgeTransactions({
      variables: { page: 1 },
    })
  }

  if (latestLoading || searchLoading) {
    content = (
      <>
        <AutoCompleteSearch
          suggestions={suggestions}
          bridgeTransactions={bridgeTransactions}
        />
        <TransactionsLoader number={50} />
      </>
    )
  } else if (latestError || searchError) {
    content = <AllTransactionsError />
  } else {
    let latestBridgeTransactions = data

    latestBridgeTransactions = _.orderBy(
      latestBridgeTransactions,
      'fromInfo.time',
      ['desc']
    )

    content = (
      <>
        <AutoCompleteSearch
          suggestions={suggestions}
          bridgeTransactions={bridgeTransactions}
        />
        <AllTransactions txns={latestBridgeTransactions} />
        <Pagination
          page={page}
          resetPage={resetPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </>
    )
  }

  return (
    <StandardPageContainer title="All Bridge Transactions">
      {content}
    </StandardPageContainer>
  )
}

/* TODO: future optimization, replace all this with headless ui combobox */

const AutoCompleteSearch = ({ suggestions, bridgeTransactions }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [input, setInput] = useState('')
  const [isValid, setValid] = useState(false)

  const onChange = (e) => {
    const userInput = e.target.value

    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    if (unLinked.length === 0) {
      setValid(false)
    }

    if (suggestions.includes(e.target.value)) {
      setValid(true)
    } else {
      setValid(false)
    }

    setInput(e.target.value)
    setFilteredSuggestions(unLinked)
    setActiveSuggestionIndex(0)
    setShowSuggestions(true)
  }

  const onClick = (e) => {
    setFilteredSuggestions([])
    setInput(e.target.innerText)
    setActiveSuggestionIndex(0)
    setShowSuggestions(false)
    setValid(true)
  }

  const onKeyDown = (key) => {
    if (key.keyCode === 13 || key.keyCode === 9) {
      setInput(filteredSuggestions[activeSuggestionIndex])
      setFilteredSuggestions([])
    }
  }

  const getBridgeTransactions = () => {
    bridgeTransactions({
      variables: { chainId: nameToChainIds[input] },
    })
    setFilteredSuggestions([])
    setInput(input)
    setActiveSuggestionIndex(0)
    setShowSuggestions(false)
  }

  const SuggestionsListComponent = () => {
    let chainIds = filteredSuggestions.map(
      (suggestion) => nameToChainIds[suggestion]
    )

    return chainIds.length ? (
      <ul className="absolute z-10 mt-1 bg-white border border-purple-500 rounded-lg shadow-lg dark:bg-gray-700">
        {chainIds.map((suggestion, index) => {
          const itemChainId = parseInt(suggestion)

          return (
            <SelectSpecificNetworkButton
              itemChainId={itemChainId}
              onClick={onClick}
              key={index}
            />
          )
        })}
      </ul>
    ) : (
      ''
    )
  }

  return (
    <>
      <div className="flex items-center mt-5">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-purple-700 focus:border-purple-500 block w-full pl-10 p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
            placeholder="Search by chain"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={input}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => window.location.reload(false)}
          >
            <XCircleIcon
              className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
              strokeWidth={2}
            />
          </button>
          {showSuggestions && input && <SuggestionsListComponent />}
        </div>
        <button
          type="button"
          className={`p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
            !isValid ? 'pointer-events-none opacity-50' : ''
          }`}
          onClick={getBridgeTransactions}
        >
          <SearchIcon className="w-5 h-5" strokeWidth={1} />
        </button>
      </div>
    </>
  )
}

export function SelectSpecificNetworkButton({
  itemChainId,
  isCurrentChain,
  active,
  onClick,
}) {
  const { chainImg, chainName } = CHAIN_INFO_MAP[itemChainId]

  const ref = useRef(null)

  useEffect(() => {
    if (active) {
      ref?.current?.focus()
    }
  }, [active])

  let activeClassName
  let activeTextClassName
  if (isCurrentChain) {
    activeClassName = getNetworkButtonBgClassName(itemChainId)
    activeTextClassName = ''
  } else {
    activeClassName = getNetworkButtonBgClassNameActive(itemChainId)
    activeTextClassName = `
      dark:text-gray-400
      dark:group-hover:text-gray-300
    `
  }

  return (
    <Button
      innerRef={ref}
      tabIndex={active ? '1' : '0'}
      outline={!isCurrentChain}
      className={`
        flex items-center w-full rounded-md
        !p-4
        cursor-pointer
        border border-transparent
        ${getNetworkButtonBorderHover(itemChainId)}
        ${getNetworkButtonBorderActive(itemChainId)}
        ${activeClassName}
        focus:outline-none
      `}
      onClick={onClick}
    >
      <img
        src={chainImg}
        alt="Switch Network"
        className="w-5 h-5 mr-2 rounded-md"
      />
      <div
        className={`
          text-primary font-medium ${activeTextClassName}
        `}
      >
        {chainName}
      </div>
    </Button>
  )
}
