import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Search } from '@pages/Home/Search'
import {
  TransactionCard,
  TransactionCardLoader,
} from '@components/TransactionCard'
import { GET_LATEST_BRIDGE_TRANSACTIONS_QUERY } from '@graphql/queries'
import { StandardPageContainer } from '@layouts/StandardPageContainer'
import { BRIDGE_TRANSACTIONS_URL } from '@urls'

import { ChainActivityCard } from './ChainActivityCard'
import { TokenActivityCard } from './TokenActivityCard'
import { SummaryMetricsCard } from './SummaryMetricsCard'

export function Home() {
  const { error, data } = useQuery(GET_LATEST_BRIDGE_TRANSACTIONS_QUERY)

  let content

  if (!data) {
    content = [...Array(10).keys()].map((i) => (
      <TransactionCardLoader key={i} />
    ))
  } else if (error) {
    content = 'Error'
  } else {
    let { latestBridgeTransactions } = data

    latestBridgeTransactions = _.orderBy(
      latestBridgeTransactions,
      'fromInfo.time',
      ['desc']
    ).slice(0, 10)

    content = latestBridgeTransactions.map((txn, i) => (
      <TransactionCard txn={txn} key={i} />
    ))
  }

  return (
    <StandardPageContainer title={'Synapse Explorer'}>
      <Search />
      <Statistics
        totalTxns={'130,249'}
        medianValue={'250,000'}
        totalValue={'293,380,100'}
      />
      {content}
      <Link
        className="text-sm text-gray-400 hover:underline"
        to={BRIDGE_TRANSACTIONS_URL}
      >
        View all bridge transactions
      </Link>
    </StandardPageContainer>
  )
}

export function Statistics() {
  return (
    <div className="grid gap-4 mt-5 mb-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
      <SummaryMetricsCard/>
      <TokenActivityCard />
      <ChainActivityCard/>
    </div>
  )
}
