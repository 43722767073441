export function MiniInfoItem({ href, icon, labelText }) {
  return (
    <a
      key={labelText}
      href={href}
      rel="noreferrer"
      className="block px-3 pt-1 pb-2 text-sm rounded-lg group hover:bg-gray-50 dark:hover:bg-gray-900"
      target="_blank"
    >
      <div>
        <p className="text-sm text-gray-500 dark:text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-400">
          {icon}
          <span className="mt-1">{labelText}</span>
        </p>
      </div>
    </a>
  )
}
