export function MoreInfoItem({ to, labelText, description, className }) {
  return (
    <a
      key={labelText}
      href={to}
      target={to[0] === '/' ? undefined : '_blank'}
      rel="noreferrer"
      className={`block px-3 pt-2 pb-2 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-900 ${className}`}
    >
      <p className="text-base font-medium text-gray-900 dark:text-gray-400">
        {labelText}
      </p>
      <p className="hidden mt-1 text-sm text-gray-500 md:block dark:text-gray-500">
        {description}
      </p>
    </a>
  )
}
