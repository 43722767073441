import {
  ChevronDoubleRightIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline'

import { CopyButtonIcon } from '@components/CopyButtonIcon'
import { ClockIcon } from '@icons/ClockIcon'
import { OriDestInfo } from '@components/BridgeTransaction/OriDestInfo'
import Card from '@tw/Card'
import { getNetworkShadow } from '@utils/styles/networks'
import { unixTimestampToUTCString } from '@utils/unixTimestampToUTCString'
import { StyleAddress, StyleHash } from '@utils/styleFields'

export function BridgeTransactionPageContent({
  kappa,
  pending,
  fromInfo,
  toInfo,
}) {
  const txn = {
    fromInfo,
    toInfo,
  }

  return (
    <div className="mt-10 text-xl">
      <div className="flex justify-center pb-10 space-x-2">
        <div className="w-1/4 flex-0">
          <OriDestInfo {...fromInfo} label="From " />
        </div>
        <div className="flex-shrink px-4 align-middle">
          <ChevronDoubleRightIcon className="w-6 h-6 mt-12 align-middle text-slate-500" />
        </div>
        <div className="w-1/4 flex-0">
          <OriDestInfo {...toInfo} label="To " />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <SourceCard txn={txn} />
      </div>
    </div>
  )
}

const SourceCard = ({ txn }) => {
  const { fromInfo, toInfo } = txn

  return (
    <div className="xs:w-full sm:w-3/4 md:w-3/4 lg:w-1/2">
      <Card
        className={`mt-5 mb-5 pb-2.5 shadow-lg ${getNetworkShadow(
          fromInfo.chainId
        )}`}
      >
        <InfoRow
          txLabel="Origin"
          addressType="Sender"
          addrLabel="Sent"
          info={fromInfo}
        />
      </Card>
      <Card
        className={`mt-5 mb-5 pb-2.5 shadow-lg ${getNetworkShadow(
          toInfo.chainId
        )}`}
      >
        <InfoRow
          txLabel="Destination"
          addressType="Recipient"
          addrLabel="Received"
          info={toInfo}
        />
      </Card>
    </div>
  )
}

function InfoRow({ txLabel, addrLabel, info, textClassName, addressType }) {
  const isDestination = txLabel === 'Destination'
  const className = isDestination ? 'text-right' : 'text-left'

  return (
    <div
      className={`grid-rows-4 text-black dark:text-gray-400 text-lg ${className}`}
    >
      <div className="flex items-center">
        <span>
          {isDestination ? (
            <ArrowNarrowRightIcon className="w-6 h-6 mr-5 text-gray-400" />
          ) : (
            ''
          )}
        </span>
        <span className="mr-2 text-right text-white ">{txLabel}: </span>
        {info.hash ? (
          <CopyButtonIcon className="w-4 h-4 mr-2" text={info.hash} />
        ) : (
          ''
        )}
        {StyleHash(info)}
      </div>
      <div className="flex items-center">
        <span>
          {isDestination ? (
            <ArrowNarrowRightIcon className="w-6 h-6 mr-5 text-transparent" />
          ) : (
            ''
          )}
        </span>
        <span className="mr-2 text-white">{addressType}: </span>
        {info.address ? (
          <CopyButtonIcon className="w-4 h-4 mr-2" text={info.address} />
        ) : (
          ''
        )}
        {StyleAddress(info)}
      </div>
      <div className="flex items-center mt-1 text-md">
        <span>
          {isDestination ? (
            <ArrowNarrowRightIcon className="w-6 h-6 mr-5 text-transparent" />
          ) : (
            ''
          )}
        </span>
        {/* <span className="mr-2 text-white">{addrLabel}: </span> */}
        {info.time ? (
          <ClockIcon className={`w-4 h-4 rounded-md mr-1 inline`} />
        ) : (
          ''
        )}
        {unixTimestampToUTCString(info.time)}
      </div>
    </div>
  )
}
