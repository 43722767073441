import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

import { CHAIN_INFO_MAP } from '@constants/networks'
import { getNetworkTextColor } from '@styles/networks'

export function ChainInfo({ chainId }) {
  const { chainName, chainImg } = CHAIN_INFO_MAP[chainId] ?? {}

  if (chainName) {
    return (
      <>
        <img
          className="inline w-4 h-4 mr-2 rounded-lg"
          src={chainImg}
          alt={chainImg}
        />
        <span className={getNetworkTextColor(chainId)}>{chainName}</span>
      </>
    )
  } else {
    return (
      <>
        <QuestionMarkCircleIcon
          className={`inline w-4 h-4 mr-2 rounded-md`}
          strokeWidth={1}
        />
        <span>--</span>
      </>
    )
  }
}
