import numeral from 'numeral'
import { useQuery } from '@apollo/client'
import {
  CurrencyDollarIcon,
  FireIcon,
  ChartBarIcon,
  ScaleIcon,
  VariableIcon,
} from '@heroicons/react/outline'

import { BRIDGE_TRANSACTIONS_COUNT } from '@graphql/queries'

export function TotalTransactionsTitle() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <FireIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Total Bridge Transactions</span>
    </div>
  )
}

export function TotalTransactions() {
  const { data } = useQuery(BRIDGE_TRANSACTIONS_COUNT, {
    variables: { duration: 'ALL_TIME' },
  })

  if (data) {
    let { bridgeTransactionsCount } = data
    let formattedCount = numeral(bridgeTransactionsCount.value).format('0,0')
    return (
      <div className="text-3xl text-center md:mt-14 lg:mt-14 sm:mt-5">
        {formattedCount}
      </div>
    )
  } else {
    return (
      <div className="h-6 mt-10 text-3xl text-center bg-slate-400 dark:bg-slate-500 animate-pulse" />
    )
  }
}

export function MedianValue() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <VariableIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Median Value</span>
    </div>
  )
}

export function MeanValue() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <ScaleIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Mean Value</span>
    </div>
  )
}

export function TotalValue() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <CurrencyDollarIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Total Bridge Volume</span>
    </div>
  )
}
