import { useQuery } from '@apollo/client'
import { FireIcon } from '@heroicons/react/outline'

import { COUNT_BY_CHAIN_ID } from '@graphql/queries'

import { ChainInfo } from '@components/misc/ChainInfo'
import { ContainerCard } from './ContainerCard'

export function ChainActivityCard() {
  return (
    <ContainerCard title={<ChainActivityTitle />}>
      <ChainActivity />
    </ContainerCard>
  )
}

function ChainActivityTitle() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <FireIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span className="text-transparent bg-clip-text bg-gradient-to-l from-purple-300 to-purple-500">
        Chain Activity <span className="text-sm">(24 hours)</span>
      </span>
    </div>
  )
}

function ChainActivity() {
  const { data: fromData } = useQuery(COUNT_BY_CHAIN_ID, {
    variables: {
      direction: 'OUT',
      hours: 24,
    },
  })
  const { data: toData } = useQuery(COUNT_BY_CHAIN_ID, {
    variables: {
      direction: 'IN',
      hours: 24,
    },
  })

  if (fromData && toData) {
    let { countByChainId: fromCountByChainId } = fromData
    let { countByChainId: toCountByChainId } = toData
    return (
      <div className="mt-5 mb-5">
        <div className="mb-5">
          <div className="mb-2 text-white text-md">Most Active Origin</div>
          <Activity data={fromCountByChainId} />
        </div>
        <div>
          <div className="mb-2 text-white text-md">Most Active Destination</div>
          <Activity data={toCountByChainId} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="mt-5 mb-5">
        <div className="mb-5">
          <span className="text-white text-md">Top Origin Chains</span>
          <ActivityLoader />
        </div>
        <div>
          <span className="text-white">Top Destination Chains</span>
          <ActivityLoader />
        </div>
      </div>
    )
  }
}

function Activity({ data }) {
  return (
    <div className="text-sm">
      {data.slice(0, 3).map((entry, i) => (
        <div key={i}>
          <ChainInfo chainId={entry.chainId} />
        </div>
      ))}
    </div>
  )
}

function ActivityLoader() {
  return (
    <div className="text-sm">
      <div className="h-3 bg-slate-400 dark:bg-slate-500 animate-pulse" />
      <div className="h-3 bg-slate-400 dark:bg-slate-500 animate-pulse" />
      <div className="h-3 bg-slate-400 dark:bg-slate-500 animate-pulse" />
    </div>
  )
}
