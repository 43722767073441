import { ClipboardCheckIcon, ClipboardCopyIcon } from '@heroicons/react/outline'
import { useState } from 'react'

export function CopyButtonIcon({ className, text = 'Text to Copy' }) {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 5000)
      },
      (err) => {
        console.log('Copy failed', err.mesage)
      }
    )
  }

  return (
    <>
      <button onClick={copyToClipboard}>
        {copied ? hasBeenCopied({ className }) : toCopy({ className })}
      </button>
    </>
  )
}

function toCopy({ className }) {
  return (
    <ClipboardCopyIcon
      className={`w-5 h-5 text-white ${className}`}
      strokeWidth={1}
    />
  )
}

function hasBeenCopied({ className }) {
  return (
    <ClipboardCheckIcon
      className={`w-5 h-5 text-green-200 ${className}`}
      strokeWidth={1}
    />
  )
}
