import ForumIcon from '@icons/ForumIcon'
import TwitterIcon from '@icons/TwitterIcon'
import DiscordIcon from '@icons/DiscordIcon'
import TelegramIcon from '@icons/TelegramIcon'
import {
  DOCS_URL,
  DISCORD_URL,
  TELEGRAM_URL,
  FORUM_URL,
  TWITTER_URL,
} from '@urls'
import Grid from '@tw/Grid'
import { MiniInfoItem } from './MiniInfoItem'
import { DocumentTextIcon } from '@heroicons/react/outline'

export function SocialButtons() {
  return (
    <Grid cols={{ xs: 2, sm: 1 }} gapY={1}>
      <MiniInfoItem
        href={DOCS_URL}
        labelText="Docs"
        icon={
          <DocumentTextIcon
            className={`
              w-4 -ml-1 mr-2  inline group-hover:text-blue-700
              dark:text-blue-700
            `}
          />
        }
      />
      <MiniInfoItem
        href={DISCORD_URL}
        labelText="Discord"
        icon={
          <DiscordIcon
            className={`
              w-4 -ml-1 mr-2  inline group-hover:text-indigo-500
              dark:text-indigo-500
            `}
          />
        }
      />
      <MiniInfoItem
        href={TELEGRAM_URL}
        labelText="Telegram"
        icon={
          <TelegramIcon
            className={`
              w-4 -ml-1 mr-2  inline group-hover:text-blue-400
              dark:text-blue-400
            `}
          />
        }
      />
      <MiniInfoItem
        href={TWITTER_URL}
        labelText="Twitter"
        icon={
          <TwitterIcon
            className={`
              w-4 -ml-1 mr-2  inline group-hover:text-sky-400
              dark:text-sky-400
            `}
          />
        }
      />
      <MiniInfoItem
        href={FORUM_URL}
        labelText="Forum"
        icon={
          <ForumIcon
            className={`
              w-4 -ml-1 mr-2  inline group-hover:text-purple-700
              dark:text-purple-700
            `}
          />
        }
      />
    </Grid>
  )
}
