import Card from "@tw/Card"

export function ContainerCard({children, className, ...props}) {
  return (
    <Card
      className={`text-gray-500 border border-indigo-500 dark:bg-gray-900 dark:text-gray-200 hover:border-purple-500 ${className}`}
      {...props}
    >
      {children}
    </Card>
  )
}