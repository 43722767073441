import { Link } from 'react-router-dom'
import { getExplorerTxUrl, getAddressesUrl } from '@urls'
import { getNetworkTextHoverColor } from '@utils/styles/networks'

export function StyleHash(sourceInfo) {
  if (sourceInfo.hash) {
    return (
      <a
        className={`${getNetworkTextHoverColor(
          sourceInfo.chainId
        )} hover:underline `}
        href={getExplorerTxUrl({
          hash: sourceInfo.hash,
          chainId: sourceInfo.chainId,
        })}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
      >
        {sourceInfo.hash.toLowerCase().slice(0, 6)}...
        {sourceInfo.hash.toLowerCase().slice(-6, sourceInfo.hash.length)}
      </a>
    )
  } else {
    return '--'
  }
}

export function StyleAddress(sourceInfo) {
  if (sourceInfo.address) {
    return (
      <Link
        className={`${getNetworkTextHoverColor(
          sourceInfo.chainId
        )} hover:underline `}
        to={getAddressesUrl({
          address: sourceInfo.address,
          chainIdTo: sourceInfo.chainId,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {sourceInfo.address.toLowerCase().slice(0, 6)}
        ...
        {sourceInfo.address.toLowerCase().slice(-4, sourceInfo.address.length)}
      </Link>
    )
  } else {
    return '--'
  }
}
