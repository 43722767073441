import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

import { CHAIN_INFO_MAP } from '@constants/networks'

export function ChainImage({ chainId, className }) {
  if (chainId) {
    const chainImg = CHAIN_INFO_MAP[chainId]
    return (
      <img
        src={chainImg.chainImg}
        className={`w-4 h-4 rounded-md mr-2 inline ${className}`}
        alt={chainImg.chainName}
      />
    )
  } else {
    return (
      <QuestionMarkCircleIcon
        className="inline w-4 h-4 mr-2 rounded-md"
        strokeWidth={1}
      />
    )
  }
}
