import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { GET_BRIDGE_TRANSACTION_BY_HASH_QUERY } from '@graphql/queries'

import Card from '@components/tailwind/Card'
import { StandardPageContainer } from '@layouts/StandardPageContainer'
import { BridgeTransactionPageContent } from './BridgeTransactionPageContent'
import { BridgeTransactionLoader } from '../../components/BridgeTransaction/BridgeTransactionLoader'

export function BridgeTransaction() {
  let { bridgeTransactionId } = useParams()

  const { error, data } = useQuery(GET_BRIDGE_TRANSACTION_BY_HASH_QUERY, {
    variables: {
      txnHash: bridgeTransactionId,
    },
  })

  let content
  if (!data) {
    content = <BridgeTransactionLoader />
  } else if (error) {
    content = 'Error'
  } else {
    let { bridgeTransactions } = data
    if (bridgeTransactions.length === 0) {
      content = <NoData transactionId={bridgeTransactionId} />
    } else {
      const bridgeTransaction = bridgeTransactions[0]
      content = <BridgeTransactionPageContent {...bridgeTransaction} />
    }
  }

  return (
    <StandardPageContainer title="Bridge Transaction">
      {content}
    </StandardPageContainer>
  )
}

const NoData = ({ transactionId }) => {
  return (
    <div className="grid place-items-center">
      <Card className="mt-10 text-sm text-center text-gray-400 dark:text-white">
        Sorry. We are still indexing complete data for this transaction:
        <div className="mt-3 opacity-50">{transactionId}</div>
      </Card>
    </div>
  )
}
