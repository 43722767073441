import { useNavigate } from 'react-router-dom'

import Card from '@tw/Card'
import { getBridgeTransactionUrl } from '@urls'

import { InfoRow } from './InfoRow'
import { getNetworkShadow, getNetworkHoverShadow } from '@utils/styles/networks'

export function TransactionCard({ txn }) {
  const { fromInfo, toInfo } = txn
  const navigate = useNavigate()

  return (
    <div
      className="overflow-visible cursor-pointer"
      role="link"
      onClick={() =>
        navigate(
          getBridgeTransactionUrl({
            hash: fromInfo.hash || toInfo.hash,
            chainIdFrom: fromInfo.chainId,
            chainIdTo: toInfo.chainId,
          })
        )
      }
    >
      <Card
        className={`mt-5 mb-5 pb-2.5 !shadow-sm  hover:!shadow-lg ${getNetworkShadow(
          fromInfo.chainId
        )} ${getNetworkHoverShadow(toInfo.chainId)} transition-all`}
      >
        <InfoRow
          txLabel="Origin"
          addrLabel="Sent"
          info={fromInfo}
          textClassName=""
        />
        <InfoRow
          txLabel="Destination"
          addrLabel="Received"
          info={toInfo}
          textClassName="text-sm opacity-50"
        />
      </Card>
    </div>
  )
}

export function AllTransactions({ txns }) {
  return txns.map((txn, i) => <TransactionCard txn={txn} key={i} />)
}

export {
  TransactionCardLoader,
  TransactionsLoader,
} from './TransactionCardLoader'
