import { useNavigate } from 'react-router-dom'
import numeral from 'numeral'

import Card from '@tw/Card'
import Grid from '@tw/Grid'

import { getAddressesUrl } from '@urls'

export function LeaderCard({ rank, address, count }) {
  const navigate = useNavigate()

  const hoverColors = {
    1: ' !shadow-yellow-600 hover:shadow-yellow-700 hover:!shadow-yellow-500 transition-all',
    2: ' !shadow-blue-600 hover:shadow-blue-700 hover:!shadow-blue-500 transition-all',
    3: ' !shadow-purple-600 hover:shadow-purple-700 hover:!shadow-purple-500 transition-all',
  }
  let hoverColor
  let borderType
  let shadowSize
  let textSize

  switch (rank) {
    case 1:
      hoverColor = hoverColors[rank]
      borderType = ' border-2 border-double'
      shadowSize = ' !shadow-md'
      textSize = ' sm:text-md lg:text-xl md:text-xl'
      break
    case 2:
      hoverColor = hoverColors[rank]
      borderType = ' border-2 border-double'
      shadowSize = ' !shadow-md'
      textSize = ' sm:text-md lg:text-xl md:text-xl'
      break
    case 3:
      hoverColor = hoverColors[rank]
      borderType = ' border-2 border-double'
      shadowSize = ' !shadow-md'
      textSize = ' sm:text-md lg:text-xl md:text-xl'
      break
    default:
      hoverColor = ' !shadow-red-600 hover:shadow-red-500'
      borderType = ' border'
      shadowSize = ' !shadow-sm'
      textSize = ' text-md'
  }

  let formattedCount = numeral(count).format('0,0')

  return (
    <div
      className="overflow-visible cursor-pointer"
      role="link"
      onClick={() => navigate(getAddressesUrl({ address }))}
    >
      <Card
        className={`mt-5 mb-5 pb-2.5 ${shadowSize} hover:!shadow-lg ${hoverColor}`}
      >
        <Grid
          gap={2}
          cols={{ xs: 1, sm: 1, md: 4, lg: 4 }}
          className="flex text-black dark:text-white whitespace-nowrap"
        >
          <div className="col-span-1 text-sm">
            <div
              className={`flex items-center justify-center w-6 h-6 ${borderType} rounded-full`}
            >
              <p className="text-white font-xs ">{rank}</p>
            </div>
          </div>
          <div className={`col-span-2 ${textSize}`}>{address}</div>
          <div className={`col-span-1 ${textSize}`}>{formattedCount}</div>
        </Grid>
      </Card>
    </div>
  )
}
