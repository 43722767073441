import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import {
  CurrencyDollarIcon,
  FireIcon,
  ChartBarIcon,
} from '@heroicons/react/outline'

import { ChainImage } from '@components/misc/ChainImage'
import { StandardPageContainer } from '@layouts/StandardPageContainer'
import Card from '@tw/Card'
import Grid from '@tw/Grid'
import { getAddressesUrl } from '@urls'
import { AssetImage } from '@components/misc/AssetImage'

import { LeaderCard } from './LeaderCard'

/* temp till we have live data */
import { leaderboardData, mostBridgedTokens, mostActiveChains } from './data'
import { useQuery } from '@apollo/client'
import { ADDRESS_RANKING } from '@graphql/queries'

export function Leaderboard() {
  const { data } = useQuery(ADDRESS_RANKING)
  let content

  if (data) {
    let { addressRanking } = data

    addressRanking = addressRanking.map((entry, i) => {
      return {
        rank: i + 1,
        address: entry.address,
        count: entry.count,
      }
    })

    content = addressRanking
      .slice(0, 25)
      .map((leader, i) => <LeaderCard key={i} {...leader} />)

    return (
      <StandardPageContainer title={'Leaderboard'}>
        <LeaderStats
          leaderData={leaderboardData}
          tokenData={mostBridgedTokens}
          chainData={mostActiveChains}
        />
        <span
          className={`
              text-2xl font-medium text-default
              bg-clip-text text-transparent bg-gradient-to-r
              from-purple-600 to-blue-600
            `}
        >
          Top Addresses
        </span>
        {content}
      </StandardPageContainer>
    )
  } else {
    return 'loading'
  }
}

function LeaderStats({ leaderData, tokenData, chainData }) {
  const navigate = useNavigate()
  const topAddresses = _.sortBy(leaderData, 'rank', ['asc']).slice(0, 5)

  return (
    <Grid gap={4} cols={{ xs: 1, sm: 1, md: 4, lg: 4 }} className="mt-5 mb-5">
      <Card
        className="col-span-2 text-gray-500 border border-indigo-500 dark:bg-gray-900 dark:text-gray-200 hover:border-purple-500"
        title={<MostActiveAddresses />}
      >
        {topAddresses.map((leader, i) => (
          <div
            key={i}
            className="hover:cursor-pointer hover:underline"
            role="link"
            onClick={() =>
              navigate(getAddressesUrl({ address: leader.address }))
            }
          >
            {leader.address}
          </div>
        ))}
        <span className="text-xs bg-red-500">[Placeholder]</span>
      </Card>
      <Card
        className="text-gray-500 border border-indigo-500 dark:bg-gray-900 dark:text-gray-200 hover:border-purple-500"
        title={<MostBridgedTokens />}
      >
        <div className="mt-10 mb-10 text-2xl text-center">
          {tokenData.map((token, i) => (
            <AssetImage
              tokenAddress={token.tokenAddress}
              chainId={token.chainId}
              key={i}
              className="w-8 h-8"
            />
          ))}
          <div className="mt-10 text-xs bg-red-500">[Placeholder]</div>
        </div>
      </Card>
      <Card
        className="text-gray-500 border border-indigo-500 dark:bg-gray-900 dark:text-gray-200 hover:border-purple-500"
        title={<MostActiveChains />}
      >
        <div className="mt-10 mb-10 text-2xl text-center">
          {chainData.map((chain, i) => (
            <ChainImage className="w-8 h-8" chainId={chain.chainId} key={i} />
          ))}
          <div className="mt-10 text-xs bg-red-500">[Placeholder]</div>
        </div>
      </Card>
    </Grid>
  )
}

function MostActiveAddresses() {
  return (
    <div className="flex items-center mb-10">
      <span className="mr-2 align-middle">
        <ChartBarIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Most Active Addresses</span>
    </div>
  )
}

function MostBridgedTokens() {
  return (
    <div className="flex items-center mb-10">
      <span className="mr-2 align-middle">
        <FireIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Most Bridged Tokens</span>
    </div>
  )
}

function MostActiveChains() {
  return (
    <div className="flex items-center mb-10">
      <span className="mr-2 align-middle">
        <CurrencyDollarIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span>Most Active Chains</span>
    </div>
  )
}
