import numeral from 'numeral'
import { useQuery } from '@apollo/client'
import { CurrencyDollarIcon } from '@heroicons/react/outline'

import {
  TOTAL_BRIDGE_AMOUNT,
  BRIDGE_TRANSACTIONS_COUNT,
} from '@graphql/queries'

import { ContainerCard } from './ContainerCard'

export function SummaryMetricsCard() {
  return (
    <ContainerCard title={<SummaryMetricsTitle />}>
      <SummaryMetrics />
    </ContainerCard>
  )
}

function SummaryMetricsTitle() {
  return (
    <div className="flex items-center">
      <span className="mr-2 align-middle">
        <CurrencyDollarIcon className="w-5 h-5 text-purple-500" />
      </span>
      <span className="text-transparent bg-clip-text bg-gradient-to-l from-purple-300 to-purple-500">
        Summary Metrics
      </span>
    </div>
  )
}

function SummaryMetrics() {
  const { data: volume } = useQuery(TOTAL_BRIDGE_AMOUNT, {
    variables: {
      duration: 'ALL_TIME',
    },
  })

  const { data: count } = useQuery(BRIDGE_TRANSACTIONS_COUNT, {
    variables: { duration: 'ALL_TIME' },
  })

  if (volume && count) {
    let {
      totalBridgeAmount: { value: totalVolume },
    } = volume
    let {
      countBridgeTransactions: { value: totalCount },
    } = count
    let formattedVolume = numeral(totalVolume).format('$0,0')
    let formattedCount = numeral(totalCount).format('0,0')

    return (
      <div className="mt-5">
        <div className="mb-10">
          <div className="mb-4 text-white">Total Bridge Volume</div>
          <div className="mt-2 mb-2 text-4xl text-center sm:text-4xl md:text-3xl lg:text-4xl">
            {formattedVolume}
          </div>
        </div>
        <div>
          <div className="mb-4 text-white">Total Transaction Count</div>
          <div className="mt-2 mb-2 text-4xl text-center sm:text-4xl md:text-3xl lg:text-4xl">
            {formattedCount}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="mt-5 mb-5">
        <div className="mb-5">
          <div className="mb-2 text-white">TOTAL BRIDGE VOLUME</div>
          <Loader />
        </div>
        <div>
          <div className="mb-2 text-white">TOTAL TRANSACTION COUNT</div>
          <Loader />
        </div>
      </div>
    )
  }
}

function Loader() {
  return <div className="h-6 bg-slate-400 dark:bg-slate-500 animate-pulse" />
}
