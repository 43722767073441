import { Fragment } from 'react'
import { Transition, Popover } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import Grid from '@tw/Grid'
import { MoreButton } from './MoreButton'
import { PageFooter } from './PageFooter'
import { SocialButtons } from './SocialButtons'
import { TopBarButtons } from './TopBarButtons'
import { SynapseTitleLogo } from './SynapseTitleLogo'
import { MobileBarButtons } from './MobileBarButtons'
import { MoreInfoButtons } from './MoreInfoButtons'

export function PageWrapper({ children }) {
  const topBarBtns = <TopBarButtons />
  const mobileBarBtns = <MobileBarButtons />
  const moreInfoBtns = <MoreInfoButtons />
  const socialBtns = <SocialButtons />

  return (
    <div
      className="min-h-screen overflow-hidden bg-gray-100 bg-no-repeat dark:bg-gray-900"
      style={{
        backgroundImage: "url('wavylinesstretch.svg')",
        backgroundPosition: 'top, center ',
      }}
    >
      <Popover className="relative ">
        <div className="flex items-center justify-between px-4 py-1 border-b border-gray-800 sm:px-6 md:justify-start md:space-x-10 ">
          <SynapseTitleLogo showText={false} />
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button
              className={`
                rounded-lg p-2 inline-flex items-center justify-center
                text-gray-400 hover:text-gray-500 hover:bg-gray-100
                dark:hover:text-gray-400 dark:hover:bg-gray-800
                focus:outline-none
              `}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex md:space-x-2">
              {topBarBtns}
            </Popover.Group>
            <div className="flex items-center md:ml-4">
              <div className="space-x-2">
                <Popover className="relative inline-block">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        as="div"
                        onMouseEnter={() => {}}
                        className={`
                          ${open ? 'text-gray-900' : 'text-purple-800'}
                          group  rounded-md inline-flex items-center  hover:text-gray-900 focus:outline-none
                        `}
                      >
                        <MoreButton open={open} />
                      </Popover.Button>
                      <PopoverPanelContainer className="-translate-x-full">
                        {moreInfoBtns}
                        {socialBtns}
                      </PopoverPanelContainer>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-100 ease-out"
          enterFrom=" opacity-0"
          enterTo=" opacity-100"
          leave="duration-75 ease-in"
          leaveFrom=" opacity-100"
          leaveTo=" opacity-0"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 transition origin-top-right transform md:hidden"
          >
            <div className="h-full min-h-full bg-white divide-y shadow-lg ring-1 ring-opacity-5 dark:bg-gray-800 divide-gray-50 dark:divide-gray-600">
              <div className="px-4 pt-1 pb-6">
                <div className="flex items-center justify-between">
                  <SynapseTitleLogo showText={true} />
                  <div className="-mr-2">
                    <Popover.Button
                      className={`
                        rounded-lg p-2 inline-flex items-center justify-center
                        text-gray-400 hover:text-gray-500 hover:bg-gray-100
                        dark:hover:text-gray-400 dark:hover:bg-gray-900
                        focus:outline-none
                      `}
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <Grid cols={{ xs: 1 }} gap={2}>
                    {mobileBarBtns}
                    {moreInfoBtns}
                  </Grid>
                </div>
              </div>
              <div className="px-2 py-6">{socialBtns}</div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <main className="relative flex-1 overflow-y-auto focus:outline-none ">
        {children}
      </main>
      <PageFooter />
    </div>
  )
}

function PopoverPanelContainer({ children, className }) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel
        className={`
          absolute z-10 left-1/2 transform-gpu
          ${className ?? '-translate-x-1/2'}
          mt-3 w-screen max-w-xs sm:px-0
        `}
      >
        <div className="overflow-hidden shadow-xl rounded-xl">
          <div className="relative grid gap-3 bg-white dark:bg-gray-800 px-2.5 py-3  sm:p-2">
            {children}
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  )
}
